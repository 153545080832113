import { PageHeader, PageHeaderProps } from 'antd';
import { useRouter } from 'next/router';
import React, { memo, useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  getSessionStorageKey,
  SESSION_STORAGE_KEY,
  setSessionStorageKey,
} from '../../services/SessionStorage';

interface IndexProps extends PageHeaderProps {
  children?: React.ReactNode;
}

const StyledPageHeader = styled(PageHeader)`
  padding-left: 0;
  padding-right: 0;

  .ant-page-header-back-button {
    &:hover {
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

const PageHeaderWithBackButton: React.FC<IndexProps> = ({ children, ...props }) => {
  const [stackSize, setStackSize] = useState(0);
  const router = useRouter();
  const key = SESSION_STORAGE_KEY.PAGE_VISITED_STACK;
  const getVisitedStack = (): string[] => getSessionStorageKey(key) ?? [];
  const setVisitedStack = (value: string[]) => setSessionStorageKey(key, value);

  const handleBackButton = () => {
    const currentStack = getVisitedStack();
    // Pop top element from stack before going back
    setVisitedStack(currentStack.splice(0, currentStack.length - 1));
    router.back();
  };

  useEffect(() => {
    const currentStack = getVisitedStack();
    const top = currentStack[currentStack.length - 1];
    // Handle cases of page refresh on tab or going back to existing path
    if (top !== router.asPath) {
      currentStack.push(router.asPath);
    }
    setStackSize(currentStack.length);
    setVisitedStack(currentStack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return (
    <StyledPageHeader
      // Hide backIcon back if initially visited page
      backIcon={stackSize > 1 ? <ArrowLeftOutlined /> : false}
      // Default empty title just to render the back button
      title=" "
      onBack={() => handleBackButton()}
      {...props}
    >
      {children}
    </StyledPageHeader>
  );
};

export default memo(PageHeaderWithBackButton);
