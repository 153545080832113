import { Result } from 'antd';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import GoBackHomeButton from '../client/components/common/GoBackHomeButton';
import PageHeaderWithBackButton from '../client/components/common/PageHeaderWithBackButton';

const Custom404: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <div style={{ margin: '0px 16px 0px 16px' }}>
      <PageHeaderWithBackButton />
      <Result
        status="404"
        title="404"
        subTitle={t('result.subtitle.page_does_not_exist')}
        extra={<GoBackHomeButton />}
      />
    </div>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default Custom404;
