import { Button } from 'antd';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const GoBackHomeButton: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Link href="/" passHref>
      <Button>{t('button.back_to_home')}</Button>
    </Link>
  );
};

export default GoBackHomeButton;
