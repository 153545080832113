export enum SESSION_STORAGE_KEY {
  PAGE_VISITED_STACK = 'PEW:PAGE_VISITED_STACK',
}

export const setSessionStorageKey = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Could not set ${key}, ${value}. Reason: ${e}`);
  }
};

export const getSessionStorageKey = (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch {
    return null;
  }
};
